<template>
  <div>
    <b-card no-title>
      <b-card-title>
        {{ $t('card.title.add.user') }}
      </b-card-title>
      <b-card-text>
        {{ $t('card.description.add.user') }}
      </b-card-text>
    </b-card>
    <b-overlay :show="loading">
    <b-card-code>
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col md="4">
              <b-form-group
                  :label="$t('form.name')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.name').toLowerCase()"
                    rules="required"
                    vid="name"
                >
                  <b-form-input
                      v-model="formData.name"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('form.name').toLowerCase()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  :label="$t('form.email')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.email').toLowerCase()"
                    rules="required|email"
                    vid="email"
                >
                  <b-form-input
                      v-model="formData.email"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('form.email').toLowerCase()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                  :label="$t('form.password')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.password').toLowerCase()"
                    rules="required"
                    vid="password"
                >
                  <b-form-input
                      v-model="formData.password"
                      :state="errors.length > 0 ? false:null"
                      placeholder="***********"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group
                  :label="$t('form.bio')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('form.bio').toLowerCase()"
                    rules="required"
                    vid="bio"
                >
                  <b-form-textarea
                      v-model="formData.bio"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('form.bio').toLowerCase()"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- submit button -->
            <b-col class="mt-1">
              <b-button
                  variant="primary"
                  type="submit"
                  class="float-right"
                  @click.prevent="submitForm"
              >
                {{ $t('label.button.save') }}
              </b-button>
              <cancel-and-g-o-t-o-previous-page class="mr-2"/>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BCard, BCardTitle, BCardText, BForm, BRow, BCol, BButton, BFormCheckbox, BOverlay, BFormTextarea,
} from 'bootstrap-vue'
import {
  required, email,
} from '@validations'
import vSelect from 'vue-select'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BCardText,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BFormTextarea,
    BOverlay,
    vSelect,
    cancelAndGOTOPreviousPage,
  },
  data() {
    return {
      role: 'user',
      formData: {
        name: '',
        email: '',
        password: '',
        bio: '',
      },
      loading: false,
      required,
      email,
    }
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loading = true
          // clone formData
          let fd = JSON.parse(JSON.stringify(this.formData))

          this.$store.dispatch('storeUser', fd).then(() => {
            this.$helpers.showSuccessToast()

            this.$acl.canListPermission(this.role)
                ? this.$router.push({name: 'users'})
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
  },
}
</script>
